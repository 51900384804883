import { request, requestFd } from '../core/request'
import store from '../store'
import axios from 'axios'

// 监管 折现图接口数据
export function _worksList(params) {
    console.log('登录请求')
    return request({
        url:'/ananops/api/v1/pri/supervision/worksList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 登录用户下的所有部门
export function _scansuperiordept(params) {
    console.log('登录用户下的所有部门')
    return request({
        // url:'/ananops/api/v1/pri/organ/scansuperiordept',
        url:'/ananops/api/v1/pri/supervision/scansuperiordepts',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 监管 柱状图接口数据
export function _deptReport(params) {
    console.log('登录请求')
    return request({
        url:'/ananops/api/v1/pri/supervision/deptReport',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 监管 服务商分析
export function _analysis(params) {
    console.log('服务商分析')
    return request({
        url:'/ananops/api/v1/pri/supervision/analysis',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 监管 员工工作统计分析
export function _staff(params) {
    console.log('')
    return request({
        url:'/ananops/api/v1/pri/supervision/staff',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 监管 标题头
export function _workerOrderNums(params) {
    console.log('')
    return request({
        url:'/ananops/api/v1/pri/supervision/workerOrderNums',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 监管 标题头
export function _count(params) {
    console.log('标题头')
    return request({
        url:'/ananops/api/v1/pri/supervision/count',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 监管 部门工单统计
export function _sector(params) {
    console.log('部门工单统计')
    return request({
        url:'/ananops/api/v1/pri/supervision/sector',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 已绑定的服务商列表
export function _freeEquipmentSP(params) {
    console.log('部门工单统计')
    return request({
        url:'/ananops/api/v1/pri/maintenance/plan/freeEquipmentSP',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 服务商分析表格数据
export function _serviceprovider(params) {
    console.log('服务商分析表格数据')
    return request({
        url:'/ananops/api/v1/pri/supervision/serviceProvider',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 工单统计饼状图
export function _serviceDept(params) {
    console.log('工单统计饼状图')
    return request({
        url:'/ananops/api/v1/pri/supervision/serviceDept',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 需求方统计柱状图
export function _oddNumberSum(params) {
    console.log('需求方统计柱状图')
    return request({
        url:'/ananops/api/v1/pri/supervision/oddNumberSum',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
//服务商统计柱状图
export function _serviceOddNumberSum(params) {
    console.log('服务商统计柱状图')
    return request({
        url:'/ananops/api/v1/pri/supervision/serviceOddNumberSum',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
//监管的头部数据
export function _platCount(params) {
    console.log('监管的头部数据')
    return request({
        url:'/ananops/api/v1/pri/supervision/platCount',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

//监管的保障工单接口
export function _platOrderList(params) {
  console.log('监管的保障工单接口')
  return request({
      url:'/ananops/api/v1/pri/supervision/orderList',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: 'false' // 是否加token
  })
}

//监管的维保工单接口
export function _platMtOrderList(params) {
  console.log('监管的维保工单接口')
  return request({
      url:'/ananops/api/v1/pri/supervision/mtorderlist',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: 'false' // 是否加token
  })
}


//导出文件
export function _checkexportReport(params) {
  console.log('导出文件')
  return request({
      url:'/ananops/api/v1/pri/supervision/exportreport',
      method: 'POST',
      data: params,
      showLoading: 'true',
      responseTypes : 'true',
      tokenStatus: 'true' // 是否加token
  })
}


//导出维修工单文件    new
export function _checkexportworkreport(params) {
  console.log('导出文件')
  return request({
      url:'/ananops/api/v1/pri/supervision/exportworkreport',
      method: 'POST',
      data: params,
      showLoading: 'true',
      responseTypes : 'true',
      tokenStatus: 'true' // 是否加token
  })
}


//导出验收工单文件    new
export function _checkexportacceptreport(params) {
  console.log('导出文件')
  return request({
      url:'/ananops/api/v1/pri/supervision/exportacceptreport',
      method: 'POST',
      data: params,
      showLoading: 'true',
      responseTypes : 'true',
      tokenStatus: 'true' // 是否加token
  })
}




//监管的保障工单接口
export function _platOrderLists(params) {
  console.log('监管的保障工单接口')
  return request({
      url:'/ananops/api/v1/pri/supervision/orderList',
      method: 'post',
      data: params,
      showLoading: 'true',
      responseTypes : 'true',
      tokenStatus: 'false' // 是否加token
  })
}